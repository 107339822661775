<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <a-row :gutter="24">
        <a-col :span="4">
          <!-- 机构树 -->
          <dept-tree ref="deptTree" :deptOptions="deptOptions" @select="clickDeptNode" />
        </a-col>
        <a-col :span="20">
          <!-- 条件搜索 -->
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="48">
                <a-col :md="8" :sm="24">
                  <a-form-item label="用户名称">
                    <a-input v-model="queryParam.userName" placeholder="请输入" allow-clear />
                  </a-form-item>
                </a-col>
                <template v-if="advanced">
                  <a-col :md="8" :sm="24">
                    <a-form-item label="状态" prop="status">
                      <a-select placeholder="请选择状态" style="width: 100%" allow-clear>
                        <a-select-option v-for="(d, index) in statusOptions" :key="index" :value="d.dictValue">{{
                          d.dictLabel
                        }}</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :md="8" :sm="24">
                    <a-form-item label="创建时间">
                      <a-range-picker
                        style="width: 100%"
                        v-model="dateRange"
                        valueFormat="YYYY-MM-DD"
                        format="YYYY-MM-DD"
                        allow-clear
                      />
                    </a-form-item>
                  </a-col>
                </template>
                <a-col :md="(!advanced && 8) || 24" :sm="24">
                  <span
                    class="table-page-search-submitButtons"
                    :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
                  >
                    <a-button type="primary" @click="handleQuery" id="tableQueryBtn"><a-icon type="search" />查询</a-button
                    >
                    <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                    <a @click="toggleAdvanced" style="margin-left: 8px">
                      {{ advanced ? '收起' : '展开' }}
                      <a-icon :type="advanced ? 'up' : 'down'" />
                    </a>
                  </span>
                </a-col>
              </a-row>
            </a-form>
          </div>
          <!-- 操作 -->
          <a-toolbar :loading="loading" refresh @refreshQuery="getList" tid="1">
            <div class="table-operations">
              <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['tenant:system:user:add']">
                <a-icon type="plus" />新增
              </a-button>
              <a-button
                type="primary"
                :disabled="single"
                @click="$refs.createForm.handleUpdate(undefined, ids)"
                v-hasPermi="['tenant:system:user:edit']"
              >
                <a-icon type="edit" />修改
              </a-button>
              <a-button
                type="danger"
                :disabled="multiple"
                @click="handleDelete"
                v-hasPermi="['tenant:system:user:remove']"
              >
                <a-icon type="delete" />删除
              </a-button>
              <a-button
                type="dashed"
                @click="$refs.importExcel.importExcelHandleOpen()"
                v-hasPermi="['tenant:system:user:import']"
              >
                <a-icon type="import" />导入
              </a-button>
              <a-button type="primary" @click="handleExport" v-hasPermi="['tenant:system:user:export']">
                <a-icon type="download" />导出
              </a-button>
              <!-- <a-button
                type="dashed"
                shape="circle"
                :loading="loading"
                :style="{ float: 'right' }"
                icon="reload"
                @click="getList"
              /> -->
            </div>
          </a-toolbar>
          <!-- 增加修改 -->
          <!-- 创建/编辑用户,单独封装了组件 -->
          <create-form
            ref="createForm"
            :deptOptions="deptOptions"
            :statusOptions="statusOptions"
            :sexOptions="sexOptions"
            @ok="getList"
            @select-tree="getTreeselect"
          />
          <!-- 分配角色数据权限对话框 -->
          <create-data-scope-form ref="createDataScopeForm" @ok="getList" />
          <!-- 修改密码抽屉 -->
          <reset-password ref="resetPassword" />
          <!-- 设置期限抽屉 -->
          <set-expiration ref="setExpiration" @ok="getList" />
          <!-- 上传文件 -->
          <import-excel ref="importExcel" @ok="getList" />
          <!-- 数据展示 -->
          <a-tabs :default-active-key="1" :activeKey="tabKey" @change="tabCallback">
            <a-tab-pane v-for="item in tabList" :key="item.key" :tab="item.tab" v-hasPermi="[item.role]">
            </a-tab-pane>
          </a-tabs>
          <a-table
            :loading="loading"
            :size="tableSize"
            rowKey="userId"
            tid="1"
            :columns="columns"
            :data-source="list"
            :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
            :pagination="false"
          >
            <span slot="status" slot-scope="text, record">
              <a-popconfirm
                ok-text="是"
                cancel-text="否"
                @confirm="confirmHandleStatus(record)"
                @cancel="cancelHandleStatus(record)"
              >
                <span slot="title">确认<b>{{ record.status === '1' ? '启用' : '停用' }}</b>{{ record.nickName }}的用户吗?</span
                >
                <a-switch checked-children="开" un-checked-children="关" :checked="record.status == 0" />
              </a-popconfirm>
            </span>
            <span slot="bindWeChat" slot-scope="text, record">
              {{ bindWeChatFormat(record) }}
            </span>
            <!-- <span slot="expirationDate" slot-scope="text, record">
              {{ parseTime(record.expirationDate) }}
            </span> -->
            <span slot="expirationDate" slot-scope="text, record" :title="expirationDateFormat(record)">
              {{ expirationDateFormat(record) }}
            </span>
            <span slot="operation" slot-scope="text, record">
              <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['tenant:system:user:edit']">
                <a-icon type="edit" />
                修改
              </a>
              <a-divider type="vertical" v-if="record.userId !== 1" v-hasPermi="['tenant:system:user:remove']" />
              <a @click="handleDelete(record)" v-if="record.userId !== 1" v-hasPermi="['tenant:system:user:remove']">
                <a-icon type="delete" />
                删除
              </a>
              <a-divider type="vertical" v-hasPermi="['tenant:system:user:resetPwd']" />
              <a @click="$refs.resetPassword.handleResetPwd(record)" v-hasPermi="['tenant:system:user:resetPwd']">
                <a-icon type="key" />
                重置
              </a>
              <a-divider type="vertical" v-hasPermi="['tenant:system:user:edit']" />
              <a @click="$refs.createDataScopeForm.handleDataScope(record)" v-hasPermi="['tenant:system:user:edit']">
                <a-icon type="lock" />数据权限
              </a>
              <a-divider type="vertical" v-hasPermi="['tenant:system:user:SetExpiration']" />
              <a
                @click="$refs.setExpiration.handleSetExpiration(record)"
                v-hasPermi="['tenant:system:user:SetExpiration']"
              >
                <a-icon type="key" />
                设置期限
              </a>
              <template v-if="record.bindWeChat === 'Y'">
                <a-divider type="vertical" />
                <a @click="handleWxUnbind(record)">
                  <a-icon type="undo" />
                  微信解绑
                </a>
              </template>
            </span>
          </a-table>

          <!-- 分页 -->
          <a-pagination
            class="ant-table-pagination"
            show-size-changer
            show-quick-jumper
            :current="queryParam.pageNum"
            :total="total"
            :page-size="queryParam.pageSize"
            :showTotal="(total) => `共 ${total} 条`"
            @showSizeChange="onShowSizeChange"
            @change="changeSize"
          />
        </a-col>
      </a-row>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { listUser, listWechatUser, listAllUser, delUser, exportUser, changeUserStatus, userWxUnbind } from '@/api/system/user'
import { treeselect } from '@/api/system/dept'
import ResetPassword from './modules/ResetPassword'
import SetExpiration from './modules/SetExpiration'
import CreateForm from './modules/CreateForm'
import ImportExcel from './modules/ImportExcel'
import DeptTree from './modules/DeptTree'
import { Popconfirm, Tabs } from 'ant-design-vue'
import CreateDataScopeForm from './modules/CreateDataScopeForm'

export default {
  name: 'User',
  components: {
    ResetPassword,
    CreateForm,
    ImportExcel,
    SetExpiration,
    DeptTree,
    APopconfirm: Popconfirm,
    CreateDataScopeForm,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane
  },
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 状态数据字典
      statusOptions: [],
      sexOptions: [],
      bindWeChatOptions: [],
      // 机构树选项
      deptOptions: [
        {
          id: 0,
          label: '',
          children: []
        }
      ],
      // 日期范围
      dateRange: [],
      queryParam: {
        pageNum: 1,
        pageSize: 10,
        userName: undefined,
        status: undefined,
        deptId: undefined
      },
      columns: [
        {
          title: '用户名',
          dataIndex: 'userName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '用户昵称',
          dataIndex: 'nickName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '所属机构',
          dataIndex: 'dept.deptName',
          ellipsis: true,
          scopedSlots: { customRender: 'dept.deptName' },
          align: 'center'
        },
        {
          title: '微信绑定',
          dataIndex: 'bindWeChat',
          ellipsis: true,
          scopedSlots: { customRender: 'bindWeChat' },
          align: 'center'
        },
        {
          title: '状态',
          dataIndex: 'status',
          ellipsis: true,
          scopedSlots: { customRender: 'status' },
          align: 'center'
        },
        {
          title: '使用期限',
          dataIndex: 'expirationDate',
          scopedSlots: { customRender: 'expirationDate' },
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '35%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      tabList: [
        { key: 1, tab: '中控会员', role: 'tenant:system:user:list', api: 'list' },
        { key: 2, tab: '微信会员', role: 'tenant:system:user:listWechat', api: 'listWechat' },
        { key: 3, tab: '所有会员', role: 'tenant:system:user:listAll', api: 'listAll' }
      ],
      tabKey: 1
    }
  },
  filters: {},
  created() {
    this.getList()
    this.getTreeselect()
    this.getDicts('sys_normal_disable').then((response) => {
      this.statusOptions = response.data
    })
    this.getDicts('sys_user_sex').then((response) => {
      this.sexOptions = response.data
    })
    this.getDicts('sys_yes_no').then((response) => {
      this.bindWeChatOptions = response.data
    })
  },
  computed: {},
  watch: {},
  methods: {
    tabCallback(e) {
      console.log('tabCallback e', e)
      console.log('tabCallback', this.tabKey)
      if (this.tabKey !== e) {
        this.tabKey = e
        this.resetQuery()
      }
    },
    /** 查询用户列表 */
    getList() {
      this.loading = true
      let method = ''
      console.log('this.tabKey', this.tabKey)
      if (this.tabKey === 1) {
        method = listUser
      } else if (this.tabKey === 2) {
        method = listWechatUser
      } else if (this.tabKey === 3) {
        method = listAllUser
      }
      method(this.addDateRange(this.queryParam, this.dateRange)).then((response) => {
        this.list = response.rows.map((u) => {
          if (u.userOauths && u.userOauths.length > 0) {
            const wechatUser = u.userOauths.filter((w) => w.oauthType === 'miniapp' || w.oauthType === 'mp')
            if (wechatUser) {
              u.bindWeChat = 'Y'
              return u
            }
          }
          u.bindWeChat = 'N'
          return u
        })
        this.total = response.total
        this.loading = false
      })
    },
    /** 查询部门下拉树结构 */
    getTreeselect() {
      treeselect().then((response) => {
        this.deptOptions = response.data
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.dateRange = []
      this.queryParam = {
        pageNum: 1,
        pageSize: 10,
        userName: undefined,
        status: undefined,
        deptId: undefined
      }
      this.handleQuery()
    },
    // 失效时间
    expirationDateFormat(row, column) {
      if (row.expirationDate) {
        return row.expirationDate
      } else {
        return '长期'
      }
    },
    // 是否绑定微信 N否Y是字典翻译
    bindWeChatFormat(row, column) {
      console.log('bindWeChatFormat')
      return this.selectDictLabel(this.bindWeChatOptions, row.bindWeChat)
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.userId)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    clickDeptNode(deptId) {
      this.queryParam.deptId = deptId
      this.getList()
    },
    /* 用户状态修改 */
    confirmHandleStatus(row) {
      const text = row.status === '1' ? '启用' : '关闭'
      row.status = row.status === '0' ? '1' : '0'
      changeUserStatus(row.userId, row.status)
        .then(() => {
          this.$message.success(text + '成功', 3)
        })
        .catch(function () {
          this.$message.error(text + '异常', 3)
        })
    },
    cancelHandleStatus(row) {},
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const userIds = row.userId || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中用户为' + row.userName + '的数据',
        onOk() {
          return delUser(userIds).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {}
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          return exportUser(that.queryParam).then((response) => {
            that.download(response.msg)
            that.$message.success('导出成功', 3)
          })
        },
        onCancel() {}
      })
    },
    // 微信解绑
    handleWxUnbind(record) {
      console.log('handleWxUnbind')
      if (record.userOauths && record.userOauths.length > 0) {
        const id = record.userOauths[0].id
        this.$confirm({
          title: '是否确认微信解绑?',
          onOk: () => {
            userWxUnbind(id).then((response) => {
              this.getList()
              this.$message.success('微信解绑成功', 3)
            })
          },
          onCancel() {}
        })
      }
    }
  }
}
</script>
