<template>
  <a-modal
    :title="title"
    :visible="open"
    @ok="submitForm"
    @cancel="cancel"
  >
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="用户名" prop="userName">
        <a-input v-model="form.userName" :disabled="true"/>
      </a-form-model-item>
      <a-form-model-item label="使用期限(留空则为不限制)" prop="expirationDate" >
        <a-date-picker style="width: 100%" v-model="form.expirationDate" value-format="YYYY-MM-DD HH:mm:ss" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>
<script>
import { setExpiration } from '@/api/system/user'

export default {
  name: 'SetExpiration',
  props: {
  },
  data () {
    return {
      title: '设置用户使用期限',
      open: false,
      childrenDrawer: false,
      formLayout: 'horizontal',
      form: {
        userName: undefined,
        expirationDate: undefined
      },
      rules: {
      }
    }
  },
  methods: {
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.form = {
        userId: undefined,
        userName: undefined,
        expirationDate: undefined
      }
    },
    handleSetExpiration (row) {
      this.form = {
        userId: row.userId,
        userName: row.userName
      }
      this.open = true
    },
    /** 设置用户使用期限按钮操作 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          setExpiration(this.form.userId, this.form.expirationDate).then(response => {
            this.$message.success(
              '设置成功',
              3
            )
            this.$emit('ok')
            this.open = false
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>
